import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './secure-transaction.style';

const SecureTransaction = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        secureTransaction {
          title
          description
          image {
            thumb {
              publicURL
            }
            bubble {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { image, title, description } = Data.appMinimalJson.secureTransaction;

  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={image.thumb.publicURL} alt="Empresa de Demoliciones Bogota" />
            
          </div>
          <div className="content">
            <Heading content={title} />
            <Text content={description} />
            <a href="tel:3042775545" className="button">
              Click para Llamar 
            </a>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default SecureTransaction;
