import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import BannerArea, { Col } from './banner.style';

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        BannerData {
          title
          text
          button {
            link
            label
          }
          image {
            publicURL
          }
          tagline
        }
      }
    }
  `);
  const {
    title,
    text,
    button,
    image,
    tagline,
  } = Data.appMinimalJson.BannerData;

  return (
    <BannerArea id="banner_section">
      <Container className="Container">
        <Col>
          <Heading as="h2" content={title} />
          <Text as="p" content={text}/>
          <Box className="ButtonWrap">
            <a href="tel:3042775545" className="Button">
              {button.label}             
            </a>
            <Text as="span" content={tagline} />
          </Box>
        </Col>
      </Container>
    </BannerArea>
  );
};

export default Banner;
