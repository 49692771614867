import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/appminimal';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Banner from 'containers/AppMinimal/Banner';
import KeyFeatures from 'containers/AppMinimal/KeyFeatures';
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import Features from 'containers/AppMinimal/Features';
import SecureTransaction from 'containers/AppMinimal/SecureTransaction';
import CallToAction from 'containers/AppMinimal/CallToAction';
import Footer from 'containers/AppMinimal/Footer';
import SEO from 'components/seo';
import image from "../images/Demoliciones-Bogota-Empresa.webp"


import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
  GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';

export default function () {

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "HomeAndConstructionBusiness",
    "name": "Demoliciones Bogota",
    "image": "https://demolicionesbogota.com/static/Demoliciones-Bogota-821bdd82c3d6d8728d5bdb6551cd044d.webp",
    "@id": "DemolicionesBogota",
    "url": "https://demolicionesbogota.com/",
    "telephone": "573042775545",
    "priceRange": "0-1",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Carrera 62 # 164 - 40",
      "addressLocality": "Bogota",
      "postalCode": "111156",
      "addressCountry": "CO"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 4.747815,
      "longitude": -74.062272
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "08:00",
      "closes": "19:00"
    },
    "sameAs": "https://demolicionesbogota.com/" 
  }


  return (
    <ThemeProvider theme={theme}>
      <>
      

        <SEO 
       title="Demoliciones Bogota. Cel: 304 277 5545 ✅ Empresa de Demoliciones." 
       description="Demoliciones Bogota. Empresa con Servicio de Demolición de Edificios Casas Muros y Construcciones. Cel: 304 277 5545 "
       image={image} 
       schemaMarkup={schema}        
       />

        <ResetCSS />
        <GlobalStyle />
  
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Banner />
            <KeyFeatures />
            <ChooseUs />
            <Features />
            <SecureTransaction />
          </ContentWrapper>
          <GradientWrapper>
            <CallToAction />
            <Footer />
          </GradientWrapper>
        </AppWrapper>
        {/* end of app minimal landing */}
      </>
    </ThemeProvider>
  );
}
