import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import { SectionHeader } from '../app-minimal.style';
import SectionWrapper from './key-features.style';

const KeyFeatures = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        keyFeatures {
          title
          description
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);

  const { title, description, features } = Data.appMinimalJson.keyFeatures;

  return (
    <SectionWrapper id="feature_section">
      <Container>
        <SectionHeader>
          <Heading content={title} />
          <Text content={description} />
        </SectionHeader>
      
      </Container>
    </SectionWrapper>
  );
};

export default KeyFeatures;
